/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
// Variables

$bodyfont: 'Poppins', sans-serif;
$headingfont: 'Poppins', sans-serif;

@use '@angular/material' as mat;
@import '~@angular/material/theming';

/*Material Theme Colors*/

$primary: mat.define-palette(mat.$blue-palette, 600);
$accent: mat.define-palette(mat.$indigo-palette, 500);
$warn: mat.define-palette(mat.$pink-palette, 500);
$theme: mat.define-light-theme($primary, $accent, $warn);
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);

// Default Theme
@include mat.all-component-themes($theme);

// custom theme
$my-accent: mat.define-palette(mat.$cyan-palette, 400);
$my-theme: mat.define-light-theme($primary, $my-accent, $warn);
.loader-theme {
	@include mat.all-component-themes($my-theme);
}

// Dark Theme

/*Theme Colors*/

$topbar: #2196f3;
$sidebar: #fff;
$sidebar-white: #e5edef;
$sidebar-alt: #f2f6f8;
$bodycolor: #eef5f9;
$headingtext: #455a64;
$bodytext: #67757c;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4;

$font-16: 16px;

$light-text: #a6b7bf;
$themecolor: #1e88e5;
$themecolor-alt: #26c6da;
$themecolor-dark: #028ee1;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #26c6da;
$warning: #ffb22b;
$primary: #7460ee;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
$secondary: #727b84;

/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e9ab2e;
$primary-dark: #6352ce;
$info-dark: #028ee1;
$red-dark: #d61f1f;
$inverse-dark: #232a37;
$dark-transparent: rgba(0, 0, 0, 0.05);

/*Normal Color*/
$white: #ffffff;
$black: #000000;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #02bec9;
$megna: #00897b;

//theme colors
$accent: #3f51b5;
$warn: #e91e63;
$primary: #1e88e5;
$my-accent: #26c6da;
$success-snackbar: #00dc43;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$table-border: #f3f1f1;
$card-brd: #d7dfe3;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #d9d9d9;
$border-mat-card: #dee2e6;

$page-wrapper-boxed-width: 1300px;

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 960px, //992 (960 is in angular flex layout, 992 is bootstrap default)
	xl: 1200px,
	xxl: 1400px
);

$header-logo-max-width: 230px;
$header-logo-max-height: 47px;

$z-index-loader: 100;
$body-bg: $black;
$grid-gutter-width: 1rem;

$headline-color: rgba(0, 0, 0, 0.87);
$headline-color-dark: $white;

$mat-chip-bg-color: #e0e0e0;

:root {
	--muted: #{$muted};
    --headline-color: #{$headline-color};
	--bg-hover: #{rgba($white, .2)};
	--bubble-bg: #{$light};
	--bubble-edge: #fff;
	--card-bg: #fff;
}

body.dark {
	--muted: #{$muted};
    --headline-color: #{$headline-color-dark};
	--bg-hover: #{rgba($white, .1)};
	--bubble-bg: #{$light};
	--card-bg: #282c34;
}
