@import '/src/assets/styles/base/base';
@import '/src/assets/styles/variable';

/*
Template Name: Material Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/

/*******************
 Topbar
*******************/
mat-toolbar {
	box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.5);

	&.topbar {
		padding: 0px ( $grid-gutter-width * .5);

		@include media-breakpoint-up(lg) {
			padding: 0px $grid-gutter-width;
		}

		& > * {
			align-items: center;
		}
	}

	.navbar-header {
		text-align: center;
		position: absolute;
		left: 50%;
		bottom: 8px;
		width: $header-logo-max-width;
		margin-left: calc( $header-logo-max-width / 2 ) * -1;
		height: $header-logo-max-height;

		.navbar-brand {
			line-height: unset;
			margin: 0;
			padding: 0;

			.logo {
				height: 47px;
				width: auto;
				margin: auto;

				&--small {
					//margin-left: 7px;
				}

				@include media-breakpoint-down(sm) {
					display: none;
				}
				@include media-breakpoint-down(lg) {
					&--big {
						display: none;
					}
					&--small {
						display: block;
					}
				}

				@include media-breakpoint-up(lg) {
					&--big {
						display: block;
					}
					&--small {
						display: none;
					}
				}
			}

			.dark-logo {
				display: none;
			}
		}
	}

	.app-search {
		display: none;
		width: 100%;
		top: 0px;
		left: 0px;
		right: 0;
		z-index: 99;
		position: absolute;

		.form-control {
			line-height: 42px;
		}

		.cl-srh-btn {
			position: absolute;
			top: 12px;
			right: 15px;
			font-size: 15px;
			color: $bodytext;
			cursor: pointer;
		}
	}

	.profile-pic {
		width: 40px;
		height: 40px;
		border-radius: 100%;
	}

	.app-search.show-search {
		display: block;
	}
}

//.minisidebar mat-toolbar .navbar-header .navbar-brand {
//	.logo {
//		display: none;
//		&--small {
//			display: block;
//		}
//	}
//}

/*******************
 Topbar
*******************/
.topbar {
	position: relative;
	/* Make sure the toolbar will stay on top of the content as it scrolls past. */
	z-index: 2;
}

.example-sidenav-container {
	/* When the sidenav is not fixed, stretch the sidenav container to fill the available space. This
	   causes `<mat-sidenav-content>` to act as our scrolling element for desktop layouts. */
	flex: 1;
}

.example-sidenav-container {
	/* When the sidenav is fixed, don't constrain the height of the sidenav container. This allows the
	   `<body>` to be our scrolling element for mobile layouts. */
	flex: 1 0 auto;
}

/*******************
Notify
*******************/

.notify {
	position: relative;
	top: -19px;
	right: 0;
}

.heartbit {
	position: absolute;
	top: -22px;
	right: -4px;
	height: 25px;
	width: 25px;
	z-index: 10;
	border: 5px solid $danger;
	border-radius: 70px;
	-moz-animation: heartbit 1s ease-out;
	animation: heartbit 1s ease-out;
	-moz-animation-iteration-count: infinite;
	-o-animation: heartbit 1s ease-out;
	-o-animation-iteration-count: infinite;
	-webkit-animation: heartbit 1s ease-out;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

$badge: 22px;

@keyframes bouncer {
	0%, 20%, 40%, 60%, 80%, 100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	50% {
		-webkit-transform: translateY(-5px);
		transform: translateY(-5px);
	}
}

@keyframes badgeAnimation {
	0% {
		transform: scale(1);
	}
	25% {
		transform: scale(1.1);
	}
	50% {
		transform: scale(1.2);
	}
	75% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes heartbit {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	25% {
		transform: scale(0.1);
		opacity: 0.1;
	}
	50% {
		transform: scale(0.5);
		opacity: 0.3;
	}
	75% {
		transform: scale(0.8);
		opacity: 0.5;
	}
	100% {
		transform: scale(1);
		opacity: 0;
	}
}

@media (max-width: 374px) {
	.srh-btn {
		display: none !important;
	}
}

.profile-pic {
	font-size: 40px;
	width: 40px !important;
	height: 40px !important;
	line-height: 40px !important;
}
.dark .mat-toolbar.mat-primary.toolbar--racebook,
.mat-toolbar.mat-primary.toolbar--racebook {
	background-image: url("~assets/images/background/header-bg.png");
	background-size: cover;
	background-color: unset;
	box-shadow: 0 1px 10px 4px rgb(0 0 0 / 20%);

	.mat-badge-active {
		//animation: badgeAnimation 1s infinite;
		animation: bouncer 1s infinite;
	}

	&.topbar {
		.topbar-content {
			margin-bottom: 12px;
			height: 40px;
		}
	}
}

mat-toolbar {
	box-shadow: none;
}

.mat-toolbar.mat-primary, .mat-toolbar {
	//background-color: unset;
}
