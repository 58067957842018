/*******************
Wall posts on home page
*******************/

.wall-post {
	position: relative;
	width: 100%;

	& &__header {
		position: absolute;
		top: 0;
		left: 0;
		margin-bottom: ($grid-gutter-width * .5);
	}

	& &__avatar {
		float: none;
		width: 40px;  // avatar size
		margin: 0 ($grid-gutter-width * .5) 0 0;
	}

	& &__name {
		font-size: 1rem;
		font-weight: 500;
		margin: 0;
	}

	& &__content {
		padding-top: 2px;
		padding-left: calc(40px + ($grid-gutter-width * .5));  // avatar size + offset
	}

	& &__text-bubble {
		position: relative;
		border-radius: $border-radius;
		padding: 0;
		font-size: .875rem;
	}

	& &__created {
		color: $muted;
		font-size: .75rem;
	}

	& &__name,
	& &__content,
	& &__created {
		line-height: 18px;
	}

	&-holder.mat-card-content {
		padding-top: 12px;
		padding-bottom: 12px;
	}
}

.wall-post-common {
	margin-top: -6px;
	border-top: 1px solid $border-mat-card;

	.mat-card-actions {
		padding-bottom: 0;
	}

	.activity-header--with-picture {
		@include media-breakpoint-up(md) {
			width: 50%;
			padding-right: 24px;
			min-height: 80px;
		}
	}

	.activity-content {
		padding-top: 0;
	}

	.activity-content--with-picture {
		@include media-breakpoint-up(md) {
			width: 50%;
			padding-right: 24px;
			min-height: 174px;
		}
		@include media-breakpoint-up(xl) {
			min-height: 224px;
		}
	}

	.activity-picture-holder {
		max-width: none;
		margin-bottom: 12px;

		@include media-breakpoint-up(md) {
			position: absolute;
			top: 0;
			right: 0;
			width: 50%;
			margin-bottom: 0;
		}
	}

	.activity-picture {
		width: 100%;
		height: 100%;
		object-fit: cover;
		margin-bottom: 0;
	}

	.activity-picture-holder,
	.activity-picture {
		height: 250px;

		@include media-breakpoint-up(xl) {
			height: 300px;
		}
	}

	.user-activity-holder {
		position: relative;
	}

	.user-activity-content {
		@include media-breakpoint-up(md) {
			width: 50%;
			padding-right: 24px;
		}
	}
	.user-activity-map-holder {
		height: 250px;

		@include media-breakpoint-up(md) {
			position: absolute;
			top: 0;
			right: 0;
			width: 50%;
			height: 100%;
		}
	}

	.user-activity-map {
		height: 100%;
	}

	.map-container {
		border-radius: 0 0 $border-radius $border-radius;

		@include media-breakpoint-up(md) {
			border-radius: 0;
		}
	}

	.user-activity-discussion {
		padding-top: 6px;
		padding-bottom: 0;
	}
}

.wall-post-common__in {
	display: block;
	position: relative;
}

.activity-article-post {
	cursor: pointer;
	position: relative;

	&__content {
		position: relative;
		min-height: 250px;

		@include media-breakpoint-up(md) {
			width: 50%;
			padding-right: 24px;
		}

		@include media-breakpoint-up(xl) {
			min-height: 300px;
		}

		.mat-card-header {
			padding-bottom: 0;

			.mat-card-title {
				margin-bottom: 6px;
			}
		}

		.mat-card-content {
			padding-top: 0;
		}
	}

	&__picture-holder {
		overflow: hidden;
		height: 250px;

		@include media-breakpoint-up(md) {
			position: absolute;
			top: 0;
			right: 0;
			width: 50%;
			margin-bottom: 0;
		}

		@include media-breakpoint-up(xl) {
			height: 300px;
		}
	}

	&__picture {
		width: 100%;
		height: 100%;
		object-fit: cover;
		margin-bottom: 0;
	}
}

.dark {
	.wall-post-common {
		border-top: 1px solid rgba(255, 255, 255, 0.3);
	}
}
