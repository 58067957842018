/*******************
Magic on/with element :-)
*******************/

$size-of-magic: 6px;
$speed-of-magic: 20s;

.make-me-magic {
	position: relative;
	background-color: var(--card-bg);

	&::before {
		content: '';
		border-radius: $border-radius;
		position: absolute;
		left: -$size-of-magic;
		top: -$size-of-magic;
		background: linear-gradient(45deg, $danger, $primary, $blue,$warning-dark, $red-dark, $danger,
		$primary, $blue,$warning-dark, $red-dark);
		background-size: 400%;
		width: calc(100% + ($size-of-magic * 2));
		height: calc(100% + ($size-of-magic * 2));
		z-index: -1;
		animation: magicOutline $speed-of-magic linear infinite;
	}
}

@keyframes magicOutline {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
}
