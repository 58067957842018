.mat-cell,
.mat-header-cell {
	padding: 0 10px;
}

.responsive-table {
	.cell-150 {
		width: 150px;
		max-width: 150px;
	}
}
@media (min-width: 600px) {
	.mat-row:hover {
		background-color: $border-color;
		cursor: pointer;
	}
}

@media (max-width: 600px) {
	.responsive-table {
		.cell-150 {
			width: auto;
			max-width: unset;
		}

		.header-label {
			width: 80px;
			display: inline-block;
			font-weight: bold;
		}

		.mat-header-row {
			display: none;
		}

		.mat-cell,
		.mat-header-cell {
			padding: 0;
		}

		.mat-row {
			flex-direction: column;
			align-items: flex-start;
			padding: 8px 15px;

			&:after {
				min-height: unset;
			}
		}

		.mat-table {
			border: 0;
			vertical-align: middle;
		}

		.mat-table caption {
			font-size: 1em;
		}

		/*  Enable this to hide header
		.mat-table .mat-header-cell {

		  border: 10px solid;
		  clip: rect(0 0 0 0);
		  height: 1px;
		  margin: -1px;
		  padding: 0;
		  position: absolute;
		  width: 1px;
		}
		*/
		.mat-table .mat-row {
			border-bottom: 5px solid #ddd;
			display: block;
		}
		/*
		.mat-table .mat-row:nth-child(even) {background: #CCC}
		.mat-table .mat-row:nth-child(odd) {background: #FFF}
		*/
		.mat-table .mat-cell {
			border-bottom: 1px solid #ddd;
			display: block;
			//font-size: 1em;
			text-align: right;
			//font-weight: bold;
			height: 30px;
			margin-bottom: 4%;
		}
		.mat-table .mat-cell:before {
			/*
			* aria-label has no advantage, it won't be read inside a table
			content: attr(aria-label);
			*/
			content: attr(data-label);
			float: left;
			font-weight: normal;

			font-size: 0.85em;
		}
		.mat-table .mat-cell:last-child {
			border-bottom: 0;
		}
		.mat-table .mat-cell:first-child {
			margin-top: 4%;
		}

		.mat-table .no-data {
			color: $bodytext;
		}

		mat-cell:last-of-type,
		mat-header-cell:last-of-type,
		mat-footer-cell:last-of-type {
			padding-right: 0;
		}
	}
}

.scrollable-table {
	display: block;
	width: 100%;
	overflow-x: auto;

	.mat-table {
		width: 100%;
		max-width: 100%;
		display: table;
		border-collapse: collapse;
	}

	.mat-row,
	.mat-header-row {
		display: table-row;
		height: 0;
	}

	.mat-cell,
	.mat-header-cell {
		word-wrap: initial;
		display: table-cell;
		height: 56px;
		vertical-align: middle;
		line-break: unset;
		white-space: nowrap;
	}

	.mat-no-data-row {
		display: table-caption;
		caption-side: bottom;
		margin-bottom: 32px;
	}
}
