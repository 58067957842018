/*******************
Articles
*******************/

.articles-list {
	.mat-card-header {
		padding: 16px 16px 0;

		.mat-card-header-text {
			margin: 0;
		}

		.mat-card-title {
			margin-bottom: 8px;

			& > a {
				color: $headline-color;
				display: block;
				text-decoration: none;
				line-height: 1.2;

				&:hover,
				&:focus {
					color: $headline-color;
					text-decoration: underline;
				}

				.dark & {
					color: $headline-color-dark;

					&:hover,
					&:focus {
						color: $headline-color-dark;
					}
				}
			}
		}

		.mat-card-subtitle {
			font-size: 12px;
		}
	}

	.mat-card-content {
		padding: 16px;

		&::after {
			display: block;
			clear: both;
			content: '';
			height: 0;
		}

		img {
			margin-left: -16px;
			margin-right: -16px;
			width: calc(100% + 16px + 16px);
			max-width: calc(100% + 16px + 16px);
		}

		&>:last-child:not(.mat-card-footer) {
			margin-bottom: 16px;
		}
	}

	.articles-article {
		&__meta {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			font-size: 14px;
		}

		&__meta-source {
			&::before {
				content: '•';
				padding-left: 4px;
				padding-right: 4px;
			}

			a {
				&,
				&:visited,
				&:hover,
				&:focus {
					color: rgba(0, 0, 0, 0.54);

					.dark & {
						color: $sidebar-text;
					}
				}
			}
		}

		&__more {
			padding: 0 16px 32px;

			a {
				display: block;
				font-size: 14px;

				&:hover,
				&:focus {
					color: $white;
				}
			}
		}
	}
}
