/*******************
Activities overview and activity
*******************/

.activities-overview {
	.row {
		margin: 0;

		& > * {
			padding-left: 0;
			padding-right: 0;
			margin: 0;
		}
	}
}



// Prevent clickable and interaction default chip on activity labels
.activity-labels {
	&.mat-chip-list {
		.mat-standard-chip {
			cursor: default;
			pointer-events: none;

			&,
			&:hover,
			&:focus,
			&:active {
				background-color: $mat-chip-bg-color;

				&::after {
					opacity: 0;
				}
			}
		}
	}
}
