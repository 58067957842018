/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */

@use '@angular/material' as mat;
@import '~@angular/cdk/overlay-prebuilt.css';
@import 'variable';
@import 'base/mixins';

@import '~bootstrap/scss/bootstrap';

@import 'icons/themify-icons/themify-icons.css';
@import 'icons/font-awesome/css/font-awesome.css';
@import 'icons/flag-icon-css/flag-icon.min.css';
@import 'spinner';
@import 'app';
@import 'header';
@import 'widgets';

@import 'horizontal.scss';

@import 'pages';
@import 'sidebar';
@import 'dark-sidebar';
@import 'responsive';
@import 'table';
@import 'rtl';
@import 'snack-bar';
@import 'fields';

@import 'activities';
@import 'articles';
@import 'wall-post';

@import 'magic';

// Red Theme

.danger {
	@import 'colors/red';
	@include mat.all-component-themes($red-app-theme);
}

// Blue Theme

.blue {
	@import 'colors/blue';
	@include mat.all-component-themes($blue-app-theme);
}

// Green Theme

.green {
	@import 'colors/green';
	@include mat.all-component-themes($green-app-theme);
}

// Dark Theme

.dark {
	.mat-drawer-container {
		color: #8999a0 !important;
	}

	@import 'colors/dark';
	@include mat.all-component-themes($dark-theme);
}

$custom-typography: mat.define-typography-config(
	$font-family: 'Poppins',
);
@include mat.core($custom-typography);
